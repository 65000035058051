<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室人员信息</h2>
        <div class="hosrow">
          <div></div>
          <div>
            <el-button
              type="primary"
              @click="depaddKeshi"
            >新增人员信息</el-button>
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          center
        >
          <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="inpatientWardName"
            label="所属病区名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="educationalBackground"
            label="学历"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="professionalTitle"
            label="职称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="position"
            label="岗位"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="lengthOfService"
            label="工龄"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="deleteFlag"
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <div
                class="activeStatus"
                v-if="scope.row.deleteFlag == 1"
              >√</div>
              <div
                class="noStatus"
                v-else
              >√</div>
            </template>
          </el-table-column> -->
          <el-table-column
            label="操作"
            align="center"
          >
            <template
              style="display: flex"
              slot-scope="scope"
            >
              <el-button
                size="mini"
                type="primary"
                @click="editKeshi(scope.row)"
              ><i class="el-icon-edit"></i></el-button>
              <el-button
                size="mini"
                type="danger"
                @click="deleteKeshi(scope.row)"
              ><i class="el-icon-delete-solid"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 点击新增人员信息弹出框 -->
    <el-dialog
      :title="diaTit"
      :visible.sync="AdddialogVisible"
      width="500px"
      :before-close="AdddialogVisibleClose"
    >
      <el-form
        :model="AddDateModel"
        ref="AddDateRef"
        :rules="AddDateRules"
        label-width="100px"
      >
        <el-form-item
          label="姓名："
          prop="name"
        >
          <el-input
            v-model="AddDateModel.name"
            placeholder="请填写姓名"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="人员类型："
          prop="type"
        >
          <el-select
            v-model="AddDateModel.type"
            placeholder="请选择人员类型"
            clearable
            @change="typeValueChange(AddDateModel.type)"
          >
            <el-option
              v-for="item in typeRange"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="所属病区："
          prop="inpatientWardId"
        >
          <el-select
            v-model="AddDateModel.inpatientWardId"
            placeholder="请选择所属病区"
            clearable
            @change="inpatValueChange(AddDateModel.inpatientWardId)"
          >
            <el-option
              v-for="item in inpatRange"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="学历："
          prop="education"
        >
          <el-select
            v-model="AddDateModel.education"
            placeholder="请选择学历"
            clearable
            @change="educationValueChange(AddDateModel.education)"
          >
            <el-option
              v-for="item in educationRange"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="职称："
          prop="title"
        >
          <el-select
            v-model="AddDateModel.title"
            placeholder="请选择职称"
            clearable
            @change="titleValueChange(AddDateModel.title)"
          >
            <el-option
              v-for="item in titleRange"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="岗位："
          prop="post"
        >
          <el-select
            v-model="AddDateModel.post"
            placeholder="请选择岗位"
            clearable
            @change="postValueChange(AddDateModel.post)"
          >
            <el-option
              v-for="item in postRange"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="工龄："
          prop="workyear"
        >
          <el-select
            v-model="AddDateModel.workyear"
            placeholder="请选择工龄"
            clearable
            @change="workyearValueChange(AddDateModel.workyear)"
          >
            <el-option
              v-for="item in workyearRange"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="btnCen">
          <el-button
            v-if="addDepClick"
            type="primary"
            :loading="addDepClickKing"
            @click="addKeshi"
          >{{diaBtn}}</el-button>
           <el-button
            v-else
            type="info"
            :loading="addDepClickKing"
          ></el-button>
        </div>

      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      diaBtn: "新增",
      diaTit: "新增人员信息",
      titleRange: [],
      educationRange: [],
      inpatRange: [],
      postRange: [],
      workyearRange: [],
      // 1医生  2护士  3技师
      typeRange: [
        {
          value: "1",
          label: "医生",
        },
        {
          value: "2",
          label: "护士",
        },
        {
          value: "3",
          label: "技师",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      // 添加科室的添加按钮
      addDepClick: true,
      addDepClickKing: false,
      // 添加科室
      AddDateModel: {
        // 科室名称
        name: "",
        type: "",
        inpatientWardId: "",
        education: "",
        title: "",
        post: "",
        workyear: "",
      },
      AddDateRules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择人员类型",
            trigger: "change",
          },
        ],
        inpatientWardId: [
          {
            required: true,
            message: "请选择所属病区",
            trigger: "change",
          },
        ],
        education: [
          {
            required: true,
            message: "请选择学历",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请选择职称",
            trigger: "change",
          },
        ],
        post: [
          {
            required: true,
            message: "请选择岗位",
            trigger: "change",
          },
        ],
        workyear: [
          {
            required: true,
            message: "请选择工龄",
            trigger: "change",
          },
        ],
      },
      // 点击添加科室弹出框
      AdddialogVisible: false,
      // 科室管理的分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      // 科室id
      departmentId: window.sessionStorage.getItem("departmentId"),
      wardId: "", //病区id
    };
  },
  created() {
    this.listhosDepartmentUser();
  },
  methods: {
    // 工龄
    async workyearfindBySys() {
      let data = {
        dictType: "hos_department_user_length_of_service",
        status: "0",
      };
      let res = await this.$axios.findBySys(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.workyearRange = res.data;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 岗位
    async postfindBySys() {
      let data = {
        dictType: "hos_department_user_position",
        status: "0",
      };
      let res = await this.$axios.findBySys(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.postRange = res.data;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    //学历
    async educationfindBySys() {
      let data = {
        dictType: "hos_department_user_educational_background",
        status: "0",
      };
      let res = await this.$axios.findBySys(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.educationRange = res.data;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 职称
    async titlefindBySys() {
      let data = {
        dictType: "hos_department_user_professional_title",
        status: "0",
      };
      let res = await this.$axios.findBySys(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.titleRange = res.data;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 所属病区列表
    async findByhosInward() {
      let data = {
        deleteFlag: "0", //数据状态（0正常 1已删除）
        id: "", //病区id
        departmentId: "", //科室id
      };
      let res = await this.$axios.findByhosInward(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.inpatRange = res.data;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 选择工龄
    workyearValueChange(val) {
      // console.log(val);
      this.AddDateModel.workyear = val;
    },
    // 选择岗位
    postValueChange(val) {
      // console.log(val);
      this.AddDateModel.post = val;
    },
    // 选择职称
    titleValueChange(val) {
      // console.log(val);
      this.AddDateModel.title = val;
    },
    // 选择学历
    educationValueChange(val) {
      // console.log(val);
      this.AddDateModel.education = val;
    },
    // 选择所属病区
    inpatValueChange(val) {
      // console.log(val);
      this.AddDateModel.inpatientWardId = val;
    },
    // 选择人员类型
    typeValueChange(val) {
      // console.log(val);
      this.AddDateModel.type = val;
    },
    // 科室管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.listhosDepartmentUser();
    },
    // 点击添加科室
    depaddKeshi() {
      this.AdddialogVisible = true;
      this.callIn();
    },
    // 点击添加科室的时候取消正则
    AdddialogVisibleClose() {
      this.AdddialogVisible = false;
      this.$refs.AddDateRef.resetFields();
      this.diaTit = "新增人员信息";
      this.diaBtn = "新增";
    },
    // 点击操作添加科室的添加
    addKeshi() {
      if (this.diaBtn == "新增") {
        // 新增
        this.addhosDepartmentUser();
      } else if (this.diaBtn == "修改") {
        // 修改
        this.editdialogKeshi();
      }
    },
    addhosDepartmentUser() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (valid) {
          this.addDepClick = false;
          this.addDepClickKing = true;
          let data = _qs.stringify({
            type: this.AddDateModel.type, //人员类型：1医生  2护士  3技师
            inpatientWardId: this.AddDateModel.inpatientWardId, //所属病区id
            name: this.AddDateModel.name, //	姓名
            educationalBackground: this.AddDateModel.education, //	学历
            professionalTitle: this.AddDateModel.title, //	职称
            position: this.AddDateModel.post, //	岗位
            lengthOfService: this.AddDateModel.workyear, //	工龄
          });
          let res = await this.$axios.addhosDepartmentUser(data);
          // console.log(res);
          this.addDepClick = true;
          this.addDepClickKing = false;
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.status == 200) {
            this.AddDateModel = {
              name: "",
              type: "",
              inpatientWardId: "",
              education: "",
              title: "",
              post: "",
              workyear: "",
            };
            this.listhosDepartmentUser();
            this.AdddialogVisible = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      });
    },
    // 点击操作删除
    async deleteKeshi(row) {
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id: row.id,
      });
      let res = await this.$axios.deleteTnward(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.listhosDepartmentUser();
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    callIn() {
      this.findByhosInward();
      this.educationfindBySys();
      this.titlefindBySys();
      this.postfindBySys();
      this.workyearfindBySys();
    },
    // 点击操作修改科室的添加
    editKeshi(row) {
      this.callIn();
      // console.log(row)
      this.wardId = row.id;
      this.diaBtn = "修改";
      this.diaTit = "修改人员信息";
      this.AdddialogVisible = true;
      this.AddDateModel.name = row.name;
      this.AddDateModel.type = row.type + "";
      this.AddDateModel.inpatientWardId = row.inpatientWardId;
      this.AddDateModel.education = Number(row.educationalBackground);
      this.AddDateModel.title = Number(row.professionalTitle);
      this.AddDateModel.post = Number(row.position);
      this.AddDateModel.workyear = Number(row.lengthOfService);
    },
    // 点击修改科室用户管理的添加
    editdialogKeshi() {
      this.$refs.AddDateRef.validate(async (valid) => {
        if (!valid) return;
        this.addDepClick = false;
        this.addDepClickKing = true;
        let data = _qs.stringify({
          id: this.wardId,
          type: this.AddDateModel.type, //人员类型：1医生  2护士  3技师
          inpatientWardId: this.AddDateModel.inpatientWardId, //所属病区id
          name: this.AddDateModel.name, //	姓名
          educationalBackground: this.AddDateModel.education, //	学历
          professionalTitle: this.AddDateModel.title, //	职称
          position: this.AddDateModel.post, //	岗位
          lengthOfService: this.AddDateModel.workyear, //	工龄
        });
        let res = await this.$axios.updateDepartmentUser(data);
        console.log(res);
        this.addDepClick = true;
        this.addDepClickKing = false;
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.status == 200) {
          // 添加科室
          this.AddDateModel = {
            name: "",
            type: "",
            inpatientWardId: "",
            education: "",
            title: "",
            post: "",
            workyear: "",
          };
          this.listhosDepartmentUser();
          this.AdddialogVisible = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 分页显示病区列表数据
    async listhosDepartmentUser() {
      let data = {
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示条数
        deleteFlag: "0", //数据状态（0正常 1已删除）
        departmentId: "", //所属科室id
        name: "", //
      };
      let { data: res } = await this.$axios.listhosDepartmentUser(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.rows;
        this.total = res.total;
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.RightDiv {
  margin: 0 auto;
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.AddUser {
  float: right;
  margin-bottom: 10px;
}

.AddUserDiv {
  width: 95%;
  margin: 0 auto;
}

.depar-dia {
  width: 100%;
}
.collapse-btn {
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

/deep/ .el-dialog__body {
  text-align: inherit;
}

.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.hosrow {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.activeStatus {
  background: rgb(102, 204, 0);
}

.noStatus {
  background: #ccc;
}

.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.btnCen {
  text-align: center;
}

/deep/ .el-input--suffix .el-input__inner,
/deep/ .el-input .el-input__inner {
  width: 300px;
}
</style>
